import { useTheme } from 'styled-components';

import { Box, Divider, Grid, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { MenuData } from '@shared/NavMenu/types';
import useWindowSize from '@shared/hooks/useWindowSize';

import MenuButton from '../MenuButton';
import MenuItemLevel1 from '../MenuItemLevel1';

import { useMegaMenu } from '../context';

const MenuHeader = ({ level1Items }: { level1Items: MenuData }) => {
  const theme = useTheme();
  const { isMediumScreen, isSmallScreen } = useWindowSize();
  const { selectedLevel1 } = useMegaMenu();

  if (isSmallScreen) {
    return (
      <>
        <Box
          sx={{
            minHeight: 48,
            display: 'flex',
            overflowX: 'auto',
            overflowY: 'hidden',
            scrollbarWidth: 'none',
          }}
        >
          {level1Items.map((item, index) => (
            <MenuItemLevel1
              key={item.title}
              item={item}
              isFirstItem={index === 0}
              isLastItem={index === level1Items.length - 1}
            />
          ))}
        </Box>
        <Divider />
      </>
    );
  }

  if (isMediumScreen) {
    return (
      <>
        <Box sx={{ display: 'flex', ml: 'medium', mr: 'medium' }}>
          {level1Items.map((item, index) => (
            <MenuItemLevel1
              key={item.title}
              item={item}
              isFirstItem={index === 0}
              isLastItem={index === level1Items.length - 1}
            />
          ))}
        </Box>
        <Divider />
      </>
    );
  }

  return (
    <Grid.Row
      sx={{ minHeight: !isMediumScreen ? 76 : undefined, alignItems: 'center' }}
    >
      <Grid.Col span={[0, 0, 12, 12, 12]}>
        <Box
          p="medium"
          pl="large"
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Box sx={{ gap: 'medium', display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: 44,
                height: 44,
                display: 'flex',
                alignItems: 'center',
                borderRadius: 'medium',
                justifyContent: 'center',
                backgroundColor: 'defaultBackground',
              }}
            >
              <Image
                width={theme.sizes.large}
                height={theme.sizes.large}
                src={`/assets/menu-icons/${selectedLevel1?.icon}.svg`}
              />
            </Box>

            <Typography.Title level={5} sx={{ whiteSpace: 'nowrap' }}>
              For {selectedLevel1?.title}
            </Typography.Title>
          </Box>
        </Box>
      </Grid.Col>

      <Grid.Col span={[0, 0, 12, 12, 12]}>
        {selectedLevel1?.meta_data && <MenuButton />}
      </Grid.Col>
    </Grid.Row>
  );
};

export default MenuHeader;
