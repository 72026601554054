import styled, { useTheme } from 'styled-components';

import { Box, Typography } from '@hero-design/react';

import useWindowSize from '@shared/hooks/useWindowSize';

import { MenuItem } from '../types';

import { useMegaMenu } from './context';

const StyledBox = styled(Box)`
  transition: background-color 0.2s ease, padding 0.2s ease;

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.defaultBackground} !important;
    border-bottom: ${({ theme }) =>
      `${theme.borderWidths.base}px solid ${theme.colors.primaryDarkBackground}`};
    cursor: pointer;
  }
`;

type MenuItemLevel1Props = {
  item: MenuItem;
  isFirstItem: boolean;
  isLastItem: boolean;
};

const MenuItemLevel1 = ({
  item,
  isFirstItem,
  isLastItem,
}: MenuItemLevel1Props) => {
  const theme = useTheme();

  const { selectedLevel1, setSelectedLevel1, setSelectedLevel2 } =
    useMegaMenu();

  const isItemSelected = selectedLevel1?.title === item.title;

  const { isSmallScreen } = useWindowSize();

  const onClick = () => {
    if (selectedLevel1?.title === item.title) {
      setSelectedLevel1(undefined);
      setSelectedLevel2(undefined);
    } else {
      setSelectedLevel1(item);
      setSelectedLevel2(item.items?.[0]);
    }
  };

  if (isSmallScreen) {
    return (
      <StyledBox
        onClick={onClick}
        sx={{
          ml: isFirstItem ? 'medium' : undefined,
          mr: isLastItem ? 'medium' : undefined,
          p: '12px',
          textAlign: 'center',
          cursor: 'pointer',
          width: '100%',
          backgroundColor: isItemSelected
            ? 'defaultBackground'
            : 'defaultLightBackground',
          borderBottom: isItemSelected
            ? `${theme.sizes.xsmall}px solid ${theme.colors.primary}`
            : undefined,
          borderTopLeftRadius: theme.radii.medium,
          borderTopRightRadius: theme.radii.medium,
        }}
      >
        <span
          style={{
            fontSize: theme.fontSizes.large,
            fontWeight: theme.fontWeights.semiBold,
            color: isItemSelected ? theme.colors.primary : undefined,
            whiteSpace: 'nowrap',
            lineHeight: '24px',
          }}
        >
          {item.title}
        </span>
      </StyledBox>
    );
  }

  return (
    <StyledBox
      p="small"
      onClick={onClick}
      sx={{
        width: '100%',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: isItemSelected
          ? 'defaultBackground'
          : 'defaultLightBackground',
        borderBottom: isItemSelected
          ? `${theme.sizes.xsmall}px solid ${theme.colors.primary}`
          : undefined,
        borderTopLeftRadius: theme.radii.medium,
        borderTopRightRadius: theme.radii.medium,
      }}
    >
      <Typography.Text
        fontSize={16}
        fontWeight="semi-bold"
        intent={isItemSelected ? 'primary' : undefined}
        sx={{ mt: 'xsmall', ml: 'medium', mr: 'medium', whiteSpace: 'nowrap' }}
      >
        {item.title}
      </Typography.Text>
    </StyledBox>
  );
};

export default MenuItemLevel1;
