import isEmpty from 'lodash/fp/isEmpty';

import { useRouter } from 'next/router';
import styled, { useTheme } from 'styled-components';

import { Box, ColProps, Grid, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { checkLinkSupportLocale } from '@shared/NavMenu/helpers';
import { MenuItem } from '@shared/NavMenu/types';

import { Link } from '../styledComponents';

type ColSpan = ColProps['span'];

type MenuItemLevelProps = {
  data: MenuItem;
};

const ColWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.space.medium}px;
  margin-top: ${({ theme }) => theme.space.large}px;
  padding-left: ${({ theme }) => theme.space.small}px;
`;

const Content = ({ items }: { items?: MenuItem[] }) => {
  const router = useRouter();

  return (
    <ColWrapper
      sx={{
        mt: 'large',
        pl: 'small',
        display: 'flex',
        gap: 'medium',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      {items?.map(
        item =>
          checkLinkSupportLocale(item.locales, router.locale) && (
            <Typography.Text key={item.title}>
              <Link item={item}>{item.title}</Link>
            </Typography.Text>
          )
      )}
    </ColWrapper>
  );
};

const Desktop = ({ data }: MenuItemLevelProps) => {
  const router = useRouter();

  const visibleItems =
    data.items?.filter(
      item =>
        item.type !== 'hidden' &&
        checkLinkSupportLocale(item.locales, router.locale)
    ) ?? [];
  const leftItems = visibleItems.filter((_, index) => index % 2 === 0);
  const rightItems = visibleItems.filter((_, index) => index % 2 !== 0);

  const colSpan: ColSpan =
    isEmpty(leftItems) || isEmpty(rightItems)
      ? [24, 24, 24, 24, 24]
      : [12, 12, 12, 12, 12];

  return (
    <Grid.Col>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ gap: 'medium', display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: 36,
              height: 36,
              display: 'flex',
              alignItems: 'center',
              borderRadius: 'medium',
              justifyContent: 'center',
              bgColor: 'defaultBackground',
            }}
          >
            <Image
              src={`/assets/menu-icons/${data.icon}.svg`}
              sx={{ width: 24, height: 24 }}
              data-test-id={data.icon}
            />
          </Box>

          <Typography.Text fontWeight="semi-bold" fontSize={16}>
            {data.title}
          </Typography.Text>
        </Box>

        <Grid>
          <Grid.Row>
            {!isEmpty(leftItems) && (
              <Grid.Col span={colSpan}>
                <Content items={leftItems} />
              </Grid.Col>
            )}

            {!isEmpty(rightItems) && (
              <Grid.Col span={colSpan}>
                <Content items={rightItems} />
              </Grid.Col>
            )}
          </Grid.Row>
        </Grid>
      </Box>
    </Grid.Col>
  );
};

const SmallScreens = ({ data }: MenuItemLevelProps) => {
  const theme = useTheme();
  const router = useRouter();

  return (
    <Box
      sx={{
        ml: 'medium',
        mb: 'large',
        gap: 'medium',
        display: 'flex',
        flexDirection: 'column',
        padding: `${theme.space.small}px ${theme.space.medium}px`,
        borderLeft: `${theme.borderWidths.base}px solid ${theme.colors.defaultBorder}`,
      }}
    >
      {data.items?.map(
        item =>
          item.type === 'item' &&
          checkLinkSupportLocale(item.locales, router.locale) && (
            <Typography.Text key={item.title}>
              <Link item={item}>{item.title}</Link>
            </Typography.Text>
          )
      )}
    </Box>
  );
};

export default {
  Desktop,
  SmallScreens,
};
