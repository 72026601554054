import { toast } from 'react-toastify';

import { Notification } from '@hero-design/react';

type AddNotificationProps = {
  intent: 'success' | 'info' | 'warning' | 'danger' | 'error';
  content: string | JSX.Element;
  title: string;
  onClose?: () => void;
};

export const addNotification = ({
  intent,
  content,
  title,
  onClose,
}: AddNotificationProps) => {
  toast(
    <Notification
      intent={intent}
      content={content}
      title={title}
      onClose={onClose}
    />,
    {
      draggable: onClose == null,
    }
  );
};
