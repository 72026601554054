import styled, { useTheme } from 'styled-components';

import { Box, Divider } from '@hero-design/react';

import { mediaMaxQueries } from '@packages/hero-theme/utils';
import { MenuData } from '@shared/NavMenu/types';
import useWindowSize from '@shared/hooks/useWindowSize';

import MenuButton from './MenuButton';
import MenuLayout from './MenuLayout';
import MenuLogin from './MenuLogin';

import { useMegaMenu } from './context';

interface MainContainerProps {
  isRegionBannerOpened: boolean;
}

const MainContainer = styled.div<MainContainerProps>`
  left: 50%;
  width: 960px;
  z-index: 100;
  display: flex;
  position: fixed;
  overflow: auto;
  flex-direction: column;
  transform: translateX(-50%);

  overflow-y: auto;
  max-height: calc(min(80svh, 662px));

  border-color: ${({ theme }) => theme.colors.defaultBorder};
  border-bottom-left-radius: ${({ theme }) => theme.radii.large}px;
  border-bottom-right-radius: ${({ theme }) => theme.radii.large}px;
  background-color: ${({ theme }) => theme.colors.defaultLightBackground};
  box-shadow: ${({ theme }) => theme.shadows.default};

  ${mediaMaxQueries.lg} {
    width: 100%;
    overflow: auto;
    box-shadow: none;
    max-height: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    height: ${({ isRegionBannerOpened }) =>
      isRegionBannerOpened ? 'calc(100svh - 150px)' : 'calc(100svh - 65px)'};
  }
`;

type MegaMenuProps = {
  level1Items: MenuData;
  isRegionBannerOpened: boolean;
};

const MegaMenu = ({ level1Items, isRegionBannerOpened }: MegaMenuProps) => {
  const theme = useTheme();

  const { isOpenMenuLogin, selectedLevel1, closeMegaMenu } = useMegaMenu();
  const { isMediumScreen } = useWindowSize();

  if (!selectedLevel1 && !isOpenMenuLogin) {
    return null;
  }

  return (
    <>
      <MainContainer isRegionBannerOpened={isRegionBannerOpened}>
        {isOpenMenuLogin ? (
          <MenuLogin />
        ) : (
          <>
            <MenuLayout.Header level1Items={level1Items} />

            <Box sx={{ overflowY: 'auto' }}>
              {isMediumScreen && selectedLevel1?.meta_data && <MenuButton />}
              <Divider />
              <MenuLayout.Body isRegionBannerOpened={isRegionBannerOpened} />
              <Divider />
              <MenuLayout.Footer />
            </Box>
          </>
        )}
      </MainContainer>

      {
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            zIndex: 99,
            backgroundColor: theme.colors.defaultDarkBackground,
            opacity: 0.75,
          }}
          onClick={() => closeMegaMenu()}
        />
      }
    </>
  );
};

export default MegaMenu;
